import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
// import { useToasts } from 'react-toast-notifications';

import MetamaskIcon from 'assets/wallet/metamask.png';
import WalletConnectIcon from 'assets/wallet/wallet-connect.png';

import { ConnectorNames, connectorsByName } from 'connectWallet/connectors';
import { getErrorMessage } from 'connectWallet/connectError';
import { UnsupportedChainIdError } from '@web3-react/core';

import { useEagerConnect, useInactiveListener } from 'hooks/useConnectWallet';

import { useStores } from 'hooks/useStore';
import "./Modal.css";

type IModal = {
    setIsOpen: (setIsOpen: boolean) => void
}

const textButton: { [connectorName: string]: string } = {
    [ConnectorNames.Injected]: 'Metamask',
    [ConnectorNames.WalletConnect]: 'Wallet Connect',
};

const connectorsIcon: { [connectorName: string]: string; } = {
    [ConnectorNames.Injected]: MetamaskIcon,
    [ConnectorNames.WalletConnect]: WalletConnectIcon,
};

const ConnectWalletModal = (props: IModal) => {

    const { connector, activate, error } = useWeb3React<Web3Provider>();
    // const { addToast } = useToasts();

    const {
        applicationStore: { setErrorMessage },
    } = useStores();

    const requestChainId = async () => {
        // if (!window.ethereum) return;
        // console.log(`!window.ethereum ${!window.ethereum}`);
        // await window.ethereum.request({
        //     method: 'wallet_addEthereumChain',
        //     params: [{
        //         chainId: '0x89',
        //         chainName: 'Matic',
        //         nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
        //         rpcUrls: ['https://rpc-mainnet.matic.network/'],
        //         blockExplorerUrls: ['https://polygonscan.com/']
        //     }]
        // })

        if (!window.ethereum) return;
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: '0x1',
                chainName: 'Ethereum Mainnet',
                nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
                rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                blockExplorerUrls: ['https://etherscan.io']
            }]
        })
    }

    useEffect(() => {
        if (error) {
            setErrorMessage({ message: getErrorMessage(error), type: 'warning' });
            if (error instanceof UnsupportedChainIdError) {
                requestChainId();
                // addToast(error.message, { appearance: 'error' });
                // console.log(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const triedEager = useEagerConnect();
    useInactiveListener(!triedEager as boolean);
    return (
        <div className="modalBackground flex w-full h-full fixed justify-center items-center z-50">
            <div className="modalContainer flex flex-col rounded-2xl bg-white shadow-2xl">
                <div className="flex flex-row">
                    <h1 className="text-xl text-black">Connect Wallet</h1>
                    <div className="flex flex-grow justify-end cursor-pointer">
                        <button
                            className="text-black"
                            onClick={() => {
                                props.setIsOpen(false);
                            }}
                        >
                            X
                        </button>
                    </div>
                </div>
                <div className="body">
                    <div className="p-6 w-full flex flex-col justify-center items-center text-black">
                        {Object.keys(connectorsByName).map((name: string) => {
                            const currentConnector = connectorsByName[name];
                            const connected = currentConnector === connector;
                            const disabled = !triedEager || connected || !!error;
                            return (
                                <button
                                    disabled={disabled}
                                    key={name}
                                    onClick={() => {
                                        // alert('hello')
                                        activate(connectorsByName[name]);
                                    }}
                                    className='my-5 flex w-full bg-white hover:bg-gray-100 shadow-md rounded-lg overflow-hidden mx-0'>
                                    <div className='w-2 bg-gray-800'></div>
                                    <div className='flex items-center px-2 py-3'>
                                        <img draggable="false" className="ml-0 mr-4 w-10 h-10 object-cover rounded-full" src={connectorsIcon[name]} alt={name} />
                                        <div className='mx-3'>
                                            <p className='text-gray-600'>{textButton[name]}</p>
                                        </div>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ConnectWalletModal };