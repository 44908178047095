/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

// component
import { Background } from "components/background";
import { Section } from "components/section";
import { FirstSection, SecondSection, ThirdSection } from "components/content";

import { ConnectWalletModal, PendingModal } from "components/Modal";
import { useEagerConnect, useInactiveListener } from "hooks/useConnectWallet";

import { useBreadman } from "hooks/useBreadman";
import { Aircraft } from "components/content/Aircraft";

interface IProps {
  darkModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const Home = ({ darkModeState }: IProps) => {
  const { totalSupply, maxSupply, price, saleState, mint } = useBreadman();

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager as boolean);
  const [isOpen, setIsOpen] = useState(false);
  const { account } = useWeb3React<Web3Provider>();

  useEffect(() => {
    if (account) setIsOpen(false);
  }, [account]);

  return (
    <>
      <Background darkMode={darkModeState[0]}>
        <Section fullHeight>
          <FirstSection
            setIsOpen={setIsOpen}
            maxSupply={maxSupply}
            minted={totalSupply}
            price={price}
            saleState={saleState}
            mint={mint}
            darkModeState={darkModeState}
          />
        </Section>
        <Aircraft darkMode={darkModeState[0]} />
        <Section fullHeight>
          <SecondSection darkMode={darkModeState[0]} />
        </Section>
        <Section fullHeight>
          <ThirdSection darkMode={darkModeState[0]} />
        </Section>
        {isOpen && <ConnectWalletModal setIsOpen={setIsOpen} />}
        <PendingModal />
      </Background>
    </>
  );
};

export default Home;
