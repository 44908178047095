import React, { ReactNode } from 'react';

type propsType = {
    fullHeight?: boolean;
    children: ReactNode;
};

export const Section: React.FC<propsType> = ({ children, fullHeight }: propsType) => {
    return (
        <div className={`section flex w-full h-auto ${fullHeight ? 'lg:h-full' : 'lg:h-screen'}`}>
            {children}
        </div>
    )
}