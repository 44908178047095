type propsType = {
  type: 'address' | 'tx' | 'token';
  address: string;
  chainId: number;
};

type URL = {
  [chainId: string]: string;
}

const url: URL = {
  1: `https://etherscan.io/`,
  4: `https://rinkeby.etherscan.io/`
}

/**
 * use for open on bscScan
 * @param {'address' | 'tx' | 'token'} Type : address | tx | token
 * @param {string} address : string
 */
const viewOnScan = ({ type, address, chainId }: propsType): string =>
  `${url[chainId]}${type}/${address}`;

export default viewOnScan;
