import Helicopter from "assets/img/Helicopter.gif";
import Plane from "assets/img/Plane.gif";

export const Aircraft = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <div className="w-full">
      {darkMode ? (
        <img
          draggable="false"
          className="lg:flex hidden w-full z-10"
          src={Helicopter}
          alt="helicopter"
        />
      ) : (
        <img
          draggable="false"
          className="lg:flex hidden w-full z-10"
          src={Plane}
          alt="plane"
        />
      )}
    </div>
  );
};