import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { observer } from 'mobx-react';
import { useStores } from 'hooks/useStore';
import viewOnScan from 'utilities/viewOnscan';



import SharePrimaryIcon from 'assets/shareIcon/share-primary.svg';


export const PendingModal: React.FC = observer((): JSX.Element => {
    const { chainId } = useWeb3React<Web3Provider>();
    const {
        applicationStore: { txHash, setTxHash },
    } = useStores();

    return (
        <>
            {
                txHash.length > 0 && chainId ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative  my-6 mx-auto w-1/3">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between ">
                                        <h3 className="text-xl font-semibold text-black">
                                        </h3>
                                        <button
                                            className="ml-auto bg-transparent border-0 text-black float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setTxHash('')}
                                        >
                                            <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="px-2 py-2 lg:px-20 text-black">
                                        <div
                                            className="flex flex-col items-center justify-start pb-2 pt-0 px-2 text-secondary bg-smoke rounded-3xl lg:bg-white"
                                            style={{
                                                height: 'auto',
                                                width: '100%',
                                            }}
                                        >
                                            <div className="p-0 w-50 h-50 animate-zoom mb-10">
                                                <div className=" flex justify-center items-center">
                                                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                                                </div>
                                            </div>
                                            <h2 className="mb-2 text-center text-secondary text-sm font-extrabold lg:text-primary">
                                                Waiting for confirmation
                                            </h2>
                                            <button
                                                onClick={() => window.open(viewOnScan({ type: 'tx', address: txHash, chainId: chainId }), '_blank')}
                                                className="mb-2 text-secondary text-sm font-normal lg:text-primary"
                                            >
                                                <img
                                                    draggable="false"
                                                    src={SharePrimaryIcon}
                                                    className="inline-block align-middle mr-2 w-4 h-4"
                                                    alt="share"
                                                />
                                                View on etherscan
                                            </button>
                                            <button
                                                onClick={() => setTxHash('')}
                                                className="my-2 py-2 w-full bg-red-200 rounded-full"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
        </>
    );
});