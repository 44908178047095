import React from "react";

// img
import Discord from "assets/social/discord.png";
import Twitter from "assets/social/twitter.png";
import CloudTop from "assets/cloud/mid/top.png";
import CloudBottom from "assets/cloud/mid/bottom.png";

const content = [
  {
    text: `
        Breadman is a community-driven collectible NFT project named after the first superhero of Breadtropolis, Location Multigrain Breadverse. The collection comprises 134 scrumptious traits spanning faces, accessories, body types, backgrounds and bread types sure to make your mouth water. All bread people are unique and you are sure to find one that matches your appetite.
    `,
  },
  {
    text: `
        Ownership of a Breadman NFT entitles holders to unfettered community access and direct control over brand deployment and event planning. All future creations to come out of the bakery will be accessible directly to holders. 
    `,
  },
  {
    text: `
        While we have plans to create a community-led pick your own adventure style comic book, release merch exclusive to holders, and deliver special treats along the way, we acknowledge the importance of member-based leadership within a decentralized platform. As such, the roadmap will be determined through community forums and participation. Together, we will make Breadman the social icon of this generation!
    `,
  },
];

const Content = () => (
  <div className="lg:w-2/3 w-3/4 mt-6 z-20">
    {content.map((item, index) => (
      <p key={index} className="text-left py-2 font-arial lg:text-2xl">
        {item.text}
      </p>
    ))}
  </div>
);

const Social = () => {
  return (
    <div className="flex flex-row w-full justify-center items-center z-20">
      <div className="mx-4 my-8">
        <a href="https://discord.gg/nXRNYPWK">
          <img draggable="false" className="w-16" src={Discord} alt="discord" />
        </a>
      </div>
      <div className="mx-4 my-8">
        <a href="https://twitter.com/thebreadverse">
          <img draggable="false" className="w-16" src={Twitter} alt="twitter" />
        </a>
      </div>
    </div>
  );
};

const Cloud = () => {
  return (
    <div className="overflow-x-hidden">
      <img
        draggable="false"
        className="animate-cloud-bottom lg:flex hidden absolute top-0 right-10 w-4/5 z-0 overflow-x-hidden"
        src={CloudTop}
        alt="cloud"
      />
      <img
        draggable="false"
        className="lg:flex hidden absolute bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 z-0 overflow-x-hidden"
        src={CloudBottom}
        alt="cloud"
      />
    </div>
  );
};

export const SecondSection = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <div className="flex flex-col w-full relative text-white justify-center items-start pb-8">
      <div className="flex flex-col w-full justify-center items-center">
        <h1 className="lg:text-5xl text-center text-2xl font-arial-rounded z-20 text-white mt-8">
          A collection of 8888 unique Breadpeople!
        </h1>
        <Content />
        <Social />
      </div>
      <Cloud />
    </div>
  );
};
