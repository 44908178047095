import { action, observable, makeAutoObservable, runInAction } from 'mobx';
/**
 * Application store interface
 * @param {boolean} isNavbarOpen : for navbar
 * @param {string} AlertInfo : [message] [type - info, warning, danger]
 */
export interface IApplicationStore {
  isNavbarOpen: boolean;
  AlertInfo: { message: string; type: string };
  txHash: string;
}

export class ApplicationStore implements IApplicationStore {
  constructor() {
    makeAutoObservable(this, {
      isNavbarOpen: observable,
      AlertInfo: observable,
      txHash: observable,
      setIsNavbarOpen: action,
      setErrorMessage: action,
      setTxHash: action
    });
  }

  isNavbarOpen = false;
  AlertInfo = { message: '', type: '' };
  txHash = '';

  setIsNavbarOpen = (value: boolean): void => {
    this.isNavbarOpen = value;
  };

  /**
   * for show modal error
   * @param {string} message
   * @param {string} type : [info, warning, danger] default is warning
   */
  setErrorMessage = (data: { message: string; type: string }): void => {
    try {
      runInAction(() => {
        this.AlertInfo.message = data.message;
        this.AlertInfo.type = data.type;
        if (data.type === '') this.AlertInfo.type = 'warning';
      });
    } catch (error) {
      console.log(error);
    }
  };

  setTxHash = (hash: string): void => {
    try {
      runInAction(() => {
        this.txHash = hash;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
