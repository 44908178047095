import React, { ReactNode } from "react";

type propsType = {
  children: ReactNode;
  darkMode: boolean;
};

export const Background: React.FC<propsType> = ({ children, darkMode }: propsType) => {
  return (
    <div
      className={`flex flex-col w-full h-auto bg-gradient-to-b ${
        darkMode
          ? "from-dark-300 via-dark-200 to-dark-50"
          : "from-light-300 via-light-200 to-light-50"
      }`}
    >
      {children}
    </div>
  );
};
