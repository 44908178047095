import React from "react";
import ReactDOM from "react-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { ToastProvider } from "react-toast-notifications";
import "tw-elements";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import getLibrary from "utilities/getLibrary";

import { Helmet } from "react-helmet";

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Breadman</title>
      <style>{'body { background-color: #739fcd; }'}</style>
    </Helmet>
    <ToastProvider
      placement="top-right"
      autoDismiss={true}
      autoDismissTimeout={4000}
    >
      <App />
    </ToastProvider>
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
