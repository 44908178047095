import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ConnectorType } from 'types/connector'

const RPC_URLS: { [chainId: number]: string } = {
  4: process.env.RPC_URL_4 as string,
  42: process.env.RPC_URL_42 as string,
}

export const injected = new InjectedConnector({ supportedChainIds: [4, 42] })

export const walletconnect = new WalletConnectConnector({
  rpc: { 4: RPC_URLS[4], 42: RPC_URLS[42] },
})

export enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
}

export const connectorsByName: { [connectorName: string]: ConnectorType } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
}
