/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
// component
import { Background } from "components/background";
import { Section } from "components/section";

import CloudLeft from "assets/cloud/top/cloud_left.png";
import CloudRight from "assets/cloud/top/cloud_right.png";
import TopLogo from "assets/logo/top.png";
import { Link } from "react-router-dom";

const content = [
  '1. Introduction: Breadman is an NFT project whose smart contract will be deployed on the Ethereum blockchain. ',
  '2. What We Own: Breadman is owned and operated by Marma Labs. All rights that are not specifically granted to the users and owners of Breadman NFTs are reserved by Marma Labs. This includes but is not limited to the intellectual property rights surrounding the “Breadman”, “BreadmanNFT”, "Multigrain Breadverse", and “Breadverse” names, logos, trademarks, the website, the look and feel of the user interface, the smart contract code, the generative traits, artwork not specific to one’s personal NFT, and any future community wallet. In addition to owning the IP surrounding the names “Breadman” and “Breadverse”, we reserve the exclusive right to use Breadman official logos for future merchandising purposes.',
  '3. You Own Your Breadperson: You Own the NFT. Each Breadperson is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying Breadperson, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any Breadperson. The initial launch of Breadman will comprise 8,888 individual ERC-721 tokens (“Breadpeople” or “NFTs”) that correspond to pieces of art hosted on IPFS. By connecting your ethereum wallet and minting Breadpeople with our smart contract, you will have complete ownership of your NFT.',
  '4. Personal Use: Subject to your continued compliance with these Terms, Marma Labs LLC grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of a marketplace that permits the purchase and sale of your Breadperson/ NFT, provided that the marketplace cryptographically verifies each Breadperson owner’s rights to display the Art for their Breadperson to ensure that only the actual owner can display the Art; or (c) as part of a third party website or application that permits the inclusion, involvement, or participation of your Breadperson, provided that the website/application cryptographically verifies each Breadperson owner’s rights to display the Art for their Breadperson to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Breadpersonleaves the website/application.',
  '5. You Own Exclusive Commercial Rights to Your Breadman NFT: You cannot use the name “Breadman” on any commercial product, or use “Breadman” unless connected directly to a hashtag (#) and the number of your Breadman NFT signifying the NFT you own. You agree not to use your Breadperson in any project or derivative work that involves hate speech, racism, pornography, or any other illegal content.Commercial Use. Subject to your continued compliance with these Terms, Marma Labs LLC grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would include the use of the Art to produce and sell merchandise (clothing, novelty items, etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (a) owning or operating a marketplace that permits the use and sale of Breadman generally, provided that the marketplace cryptographically verifies each Breadman owner’s rights to display the Art for their Breadperson to ensure that only the actual owner can display the Art; (b) owning or operating a third party website or application that permits the inclusion, involvement, or participation of Breadman generally, provided that the third party website or application cryptographically verifies each Breadman owner’s rights to display the Art for their Breadperson to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Breadperson leaves the website/application; or (c) earning revenue from any of the foregoing. Nothing in this TOS is meant to restrict a third party website’s ability to build tools which track traits or sales.',
  '6. Limitations of Liability for Gas, Failed Transactions, Smart Contract Bugs: We have worked very hard to make sure that there are no bugs in our smart contract and that our initial NFT launch is smooth. As we have seen in many previous projects, however, sometimes things break. You agree to hold The Company harmless for any losses you may incur as a consequence of minting your NFT. These potential losses include any gas fees for failed transactions, any excessive gas fees charged due to website or smart contract bugs, and any loss of your NFT due to website or smart contract bugs.',
  '7. No Guarantees or Future Promises: Marma Labs hopes to continue to develop this project and community. We have released a roadmap outlining some of the future goals we hope to accomplish. While we intend to work towards those goals, sometimes things happen and we cannot guarantee that this original project will sell out entirely nor can we guarantee future developments. You agree that your purchase of a Breadperson from our initial project is all you are guaranteed to receive with your initial purchase. Any future potential airdrops, community gatherings, or other benefits are ancillary to this purchase and not to be taken into consideration with your initial purchase. You agree that you are not relying on any future commitments by Marma Labs in using this site and participating in our NFT launch.',
  '9. Breadman NFTs Are Not Intended as Investments: NFTs are meant to be a fun avatar and Non-Fungible Token for you to collect. They are not meant as investment vehicles. We make absolutely no promise or guarantee that these NFTs will be worth anything. You understand that they have no inherent monetary value, and they should be treated as nothing more than a fun and memeable collectible.',
  '10. Taxes: You are entirely responsible for any tax liability which may arise from minting or reselling your Breadman NFT.',
  '11. Class Action Waiver: You agree to waive any class action status, and any legal dispute around the Breadman project which you may choose to bring can only be done on an individual basis.',
  '12. Children: The Breadman project is not targeted towards children. By purchasing an NFT from our project, you agree that you are over the age of 18, or above the legal age of your jurisdiction, whichever is greater.',
  '13. Arbitration: In the event that a legal dispute arises from anything related to the Breadman project, you agree to bring the case to.',
  '14. Jurisdiction and Choice of Law: You agree that for purposes of any legal dispute, you will be subject to the jurisdiction of The United States of America (US) and that any legal proceeding will be brought in The US.',
  '15. Privacy Policy: You agree that you understand that the Ethereum blockchain network is a public blockchain, and that all of your transaction history initiated through the website will be made public. You also understand that Marma Labs may work with third-party apps, such as Discord or Collab.Land, which collect and store user data. In the future, Marma Labs will likely launch products (for example merchandise) which requires us to collect and store user data. In that event, this provision is subject to change.'
];

const Content = () => (
  <div className="lg:w-2/3 w-3/4 mt-10 z-20">
    {content.map((item, index) => (
      <p key={index} className="text-left py-1 font-arial lg:text-md">
        {item}
      </p>
    ))}
  </div>
);

type imageProps = {
  className: string;
  alt: string;
  src: string | undefined;
};

const Image = ({ className, alt, src }: imageProps) => {
  return <img draggable="false" className={className} src={src} alt={alt} />;
};

const Cloud = () => {
  return (
    <>
      <div>
        <Image
          className="animate-cloud-top-left lg:flex hidden w-96 absolute bottom-2 left-0"
          src={CloudLeft}
          alt="cloud"
        />
      </div>
      <div>
        <Image
          className="animate-cloud-top-right lg:flex hidden w-96 absolute top-1/2 right-0 transform -translate-y-1/2"
          src={CloudRight}
          alt="cloud"
        />
      </div>
    </>
  );
};

interface IProps {
  darkModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const Legal = ({ darkModeState }: IProps) => {
  return (
    <>
      <Background darkMode={darkModeState[0]}>
        <Section fullHeight>
          <div className="flex flex-col w-full text-white pb-8 z-20 mb-48">
            <div className="flex flex-col justify-between items-center mt-28">
              <Link className="lg:w-1/5 w-3/5" to="/">
                <Image className="" src={TopLogo} alt="logo" />
              </Link>
              <Content />
            </div>
            <Cloud />
          </div>
        </Section>
      </Background>
    </>
  );
};

export default Legal;
