import { useState, useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';

export const useOnBlock = (): string => {
  const { library } = useWeb3React<Web3Provider>();
  const [blockNumber, setBlockNumber] = useState<string>('');

  useEffect(() => {
    if (!library) return;
    let clearInstance: NodeJS.Timeout;
    const fetchData = () => {
      setBlockNumber(new Date().getTime().toString());
      clearInstance = setTimeout(() => {
        fetchData();
      }, 5000);
    };
    fetchData();
    return () => {
      clearTimeout(clearInstance);
    };
  }, [library]);
  return blockNumber;
};
