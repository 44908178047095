import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { utils, BigNumber } from 'ethers';
import 'antd/dist/antd.min.css'
import { Switch } from 'antd';

// img
import TopBanner from 'assets/cloud/top_banner.png';
import TopLogo from 'assets/logo/top.png';
import Breadman from 'assets/img/breadman.png';
import CloudLeft from 'assets/cloud/top/cloud_left.png';
import CloudRight from 'assets/cloud/top/cloud_right.png';

// utils
import { shortenAddress } from 'utilities/checkAddress';
import { mintType } from 'types/mintType';

interface navProps {
    setIsOpen: (setIsOpen: boolean) => void;
    darkModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

interface propsTypes extends navProps {
    maxSupply: string;
    minted: string;
    price: string;
    saleState: string;
    mint: ({ amount, saleState }: mintType) => Promise<void>;
}

type headerProps = {
    text: string;
    className?: string;
}

type imageProps = {
    className: string;
    alt: string;
    src: string | undefined;
}

const Text = ({ text, className }: headerProps) => {
    return <h1 className={className}>{text}</h1>
}

const Image = ({ className, alt, src }: imageProps) => {
    return <img draggable="false" className={className} src={src} alt={alt} />
}

const Top = ({ setIsOpen, darkModeState }: navProps) => {
    const { account } = useWeb3React<Web3Provider>();
    return (
        <div className="absolute top-0 w-screen">
            <Image className='h-48 w-full object-cover -translate-y-1' src={TopBanner} alt='top-banner' />
            <div className='absolute lg:pl-0 pl-4 lg:top-8 top-4 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2'>
                <i>
                    <Text className='font-arial-rounded text-white' text="Breadman will be launching soon!" />
                </i>
            </div>
            <div className='absolute right-4 top-8 transform -translate-y-1/2'>
                <Switch onChange={()=>darkModeState[1](!darkModeState[0])} />
                <button disabled={account ? true : false} onClick={() => setIsOpen(true)} className='ml-5 border-2 border-custom-bg-300 p-2 rounded-full'>
                    {account ? shortenAddress(account) : 'Connect Wallet'}
                </button>
            </div>
        </div>
    );
}

const Cloud = () => {
    return (
        <>
            <div className='overflow-x-hidden'>
                <Image className='animate-cloud-top-left lg:flex hidden w-96 absolute bottom-2 left-0 overflow-x-hidden' src={CloudLeft} alt='cloud' />
            </div>
            <div className='overflow-x-hidden'>
                <Image className='animate-cloud-top-right lg:flex hidden w-96 absolute top-1/2 right-0 transform -translate-y-1/2 overflow-x-hidden' src={CloudRight} alt='cloud' />
            </div>
        </>
    );
}

export const FirstSection: React.FC<propsTypes> = ({
    minted,
    price,
    maxSupply,
    saleState,
    setIsOpen,
    darkModeState,
    mint }: propsTypes) => {
    const [amount, setAmount] = useState<string>('0');
    const _price = utils.formatUnits(BigNumber.from(price).toString()).toString();
    return (
        <div className='flex flex-col w-full text-white z-20'>
            <Top setIsOpen={setIsOpen} darkModeState={darkModeState} />
            <div className='flex flex-col justify-between items-center mt-28'>
                <Image className='lg:w-2/5 w-3/5' src={TopLogo} alt='logo' />
                <Image className='w-96 px-10 z-10' src={Breadman} alt='Breadman' />
                {/* <Text className='font-arial text-xl lg:mt-12 mt-10 text-white' text={`${minted}/${maxSupply} left at ${_price} ETH each`} /> */}
                {/* <input
                    onChange={(e) => setAmount(e.target.value)}
                    type={'number'}
                    min={1}
                    max={10}
                    className="lg:w-1/12 w-3/12 h-10 text-center focus:outline-none z-0 rounded-md text-black mt-5"
                    placeholder="10"
                />
                <button
                    onClick={() => mint({ amount, saleState, price })}
                    className='lg:w-1/12 w-3/12 h-10 uppercase rounded-md text-black bg-white mt-2'>
                    mint
                </button> */}
            </div>
            <Cloud />
        </div>
    );
}