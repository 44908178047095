import { useEffect, useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract, BigNumber } from 'ethers';
import { useToasts } from 'react-toast-notifications';

// import ABI from 'constants/abi';
import ABI from 'constants/abi/Breadman.json';

import { useOnBlock } from 'hooks/useOnBlock';
import { useStores } from './useStore';
import { contractAddr } from 'constants/address';
import { mintType } from 'types/mintType';

import whitelist from 'constants/whitelist/whitelist.json';

type claimsObj =  {
    index: number;
    amount: string;
    proof: string[];
};

const claims: {
    [key:string]:claimsObj
} = whitelist['claims'];

export const useBreadman = () => {
    const { account, library, chainId } = useWeb3React<Web3Provider>();
    const { addToast } = useToasts();
    const blockNumber = useOnBlock();
    const [totalSupply, setTotalSupply] = useState<string>('0');
    const [maxSupply, setMaxSupply] = useState<string>('0');
    const [price, setPrice] = useState<string>('0');
    const [saleState, setSaleState] = useState<string>('0');

    const {
        applicationStore: { setTxHash },
    } = useStores();

    useEffect(() => {
        if (!chainId || !library || !account) return;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, library, account, blockNumber])

    const getData = useCallback(async () => {
        try {
            if (!chainId || !library || !account) return;
            const contract = new Contract(
                contractAddr[chainId],
                ABI,
                library.getSigner(account).connectUnchecked(),
            );

            const [totalSupply, MAX_SUPPLY, price, saleState] = await Promise.all([contract.totalSupply(), contract.MAX_SUPPLY(), contract.price(), contract.saleState()])

            setTotalSupply(totalSupply);
            setMaxSupply(MAX_SUPPLY);
            setPrice(price);
            setSaleState(saleState);
        } catch (error) {
            console.log(error);
        }
    }, [chainId, library, account])

    const mint = useCallback(async ({ amount, saleState, price }: mintType) => {
        try {
            if (!chainId || !library || !account) return;
            const contract = new Contract(
                contractAddr[chainId],
                ABI,
                library.getSigner(account).connectUnchecked(),
            );

            const method = BigNumber.from(saleState).eq('1') ? contract.preSaleAdopt : contract.adopt;
            const _price = BigNumber.from(price).mul(BigNumber.from(amount));
            const allowance = BigNumber.from(claims[account]['amount']).toString();
            const proof = claims[account]['proof'];
            
            const res =BigNumber.from(saleState).eq('1') ? 
            await method(amount, allowance, proof, {
                from: account,
                value: _price
            }) :
            await method(amount, {
                from: account,
                value: _price
            })
            setTxHash(res['hash']);
            await res.wait();
            setTxHash('');
        } catch (error: any) {
            let message = ""
            if(error.data === "Reverted" || error.code === 4001 || error.code === -32016) {
                message = error.message
            } else if(typeof (error.data) != 'undefined'){
                message = error.data.message
            }else {
                message = error.message
            }
            addToast(message, { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, library, account])

    return { totalSupply, maxSupply, price, saleState, mint }
}