/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./App.css";

import Home from "pages/Home";
import Legal from "pages/Legal";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  const hours = new Date().getHours();
  const isDayTime = hours > 6 && hours < 19;

  const darkModeState = useState(!isDayTime);

  return (
    <Router>
      <Routes>
        <Route path="/legal" element={<Legal darkModeState={darkModeState} />} />
        <Route path="/" element={<Home darkModeState={darkModeState} />} />
      </Routes>
    </Router>
  );
}

export default App;
