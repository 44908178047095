import React from "react";

// img
import CloudRightSide from "assets/cloud/bottom/right_side.png";
import CloudLeftSide from "assets/cloud/bottom/left_side.png";
import CloudTopRight from "assets/cloud/bottom/top_right.png";
import CloudTopLeft from "assets/cloud/bottom/top_left.png";
import BreadSign from "assets/img/breadsign.png";
import Kaufibreak from "assets/profile/Kaufibreak.png";
import Larin from "assets/profile/Larin.png";
import Kettu from "assets/profile/Kettu.png";
import Sophrosyne3D from "assets/profile/Sophrosyne3D.png";
import Km1lls from "assets/profile/Km1lls.png";

import BottomBanner from "assets/img/bottom_banner.png";
import BottomBannerNight from "assets/img/bottom_banner_night.png";

import BottomLogo from "assets/logo/bottom.png";
import { Link } from "react-router-dom";

type teamTypes = {
  profile: string;
  contact: string;
  content: string;
};
const team: teamTypes[] = [
  {
    profile: Kaufibreak,
    contact: "@Kaufibreak",
    content: "Carb-Motivated Team Lead",
  },
  {
    profile: Kettu,
    contact: "@Kettu",
    content:
      "Artist",
  },
  {
    profile: Sophrosyne3D,
    contact: "@Sophrosyne3D",
    content:
      "VFX Wizard",
  },
  {
    profile: Larin,
    contact: "@Larin",
    content:
      "Developer",
  },
  {
    profile: Km1lls,
    contact: "@Km1lls",
    content:
      "Marketing",
  },
];

const Card = ({ profile, contact, content }: teamTypes) => {
  return (
    <div className="flex flex-col space-y-4 mt-10 justify-center items-center text-white">
      <img draggable="false" src={profile} alt="profile" className="rounded-full" />
      <h1 className="lg:text-3xl text-xl text-center font-arial-rounded text-white">
        {contact}
      </h1>
      <p className="text-center font-arial lg:text-xl">{content}</p>
    </div>
  );
};

const Team = () => {
  return (
    <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4 w-3/4 mb-40 z-10">
      {team.map((item, index) => (
        <Card
          key={index}
          profile={item.profile}
          contact={item.contact}
          content={item.content}
        />
      ))}
    </div>
  );
};

const Footer = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <div className="z-0">
      <img
        draggable="false"
        className="absolute bottom-0 left-0  w-2/6 lg:flex hidden"
        src={CloudLeftSide}
        alt="banner"
      />
      <img
        draggable="false"
        className="absolute bottom-0 right-0 w-2/6 lg:flex hidden"
        src={CloudRightSide}
        alt="banner"
      />
      <img
        draggable="false"
        className="absolute bottom-0 right-10 w-1/6 lg:flex hidden origin-bottom-left animate-light-move"
        src={BreadSign}
        alt="banner"
      />
      {darkMode ? (
        <img
          draggable="false"
          className="w-full absolute bottom-0 left-0 right-0 object-cover h-64"
          src={BottomBannerNight}
          alt="banner"
        />
      ) : (
        <img
          draggable="false"
          className="w-full absolute bottom-0 left-0 right-0 object-cover h-64"
          src={BottomBanner}
          alt="banner"
        />
      )}
    </div>
  );
};

const Cloud = () => {
  return (
    <div className="lg:flex hidden z-0">
      <img draggable="false" className="absolute top-0 right-0" src={CloudTopRight} alt="cloud" />
      <img draggable="false" className="absolute top-0 left-0" src={CloudTopLeft} alt="cloud" />
    </div>
  );
};

export const ThirdSection = ({ darkMode }: { darkMode: boolean }) => {
  return (
    <div className="flex flex-col relative text-white justify-center items-center py-8 pb-32">
      <div className="flex flex-col w-full justify-center items-center">
        <h1 className="lg:text-5xl text-xl font-arial-rounded text-white">
          The Bread Team
        </h1>
      </div>
      <Cloud />
      <Team />
      <Footer darkMode={darkMode} />
      <div className="z-10 absolute lg:bottom-0 bottom-5 flex flex-wrap items-center w-full">
        <div className="w-1/3 px-5 text-left lg:text-sm text-xs">
          Copyright 2021 Marmalabs
        </div>
        <div className="w-1/3">
          <img draggable="false" className="lg:h-20 mx-auto" src={BottomLogo} alt="logo" />
        </div>
        <Link className="w-1/3 px-5 text-right lg:text-sm text-xs text-white" to="/legal">
          Legal
        </Link>
      </div>
    </div>
  );
};
